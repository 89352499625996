import React, { useEffect } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import closeButton from 'icons/edit_white.png'
import cprImage from 'images/cpr-verification.png'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import getTexts from 'utils/localization'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    box-sizing: border-box;
`
const ClosePopup = styled.div`
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    background: #c1c1c1;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    transition: all 0.3s ease;
    &:hover {
        background: #585858;
    }

    img {
        height: 10px;
        width: 10px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`
const Text = styled.p`
    font-size: 1rem;
    color: #818181;
    padding-left: 10px;
`
const Title = styled.h3`
    font-size: 1.5rem;
    margin: 0;
    padding-left: 10px;
    color: #333333;
`
const Description = styled(Text)`
    margin: 19px 0;
`
const VerificationImage = styled.img`
    width: 100%;
`
const BottomText = styled(Text)`
    font-style: italic;
    font-weight: 500;
    margin: 30px 0;
`
const StyledButton = styled(Button)`
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 8px;
    font-size: 1.2rem;
    margin: 0 auto;
`

const t = getTexts().verification

const VerificationPopup = (props) => {
    const closePopup = (e) => {
        e.preventDefault()
        ModalService.closeModal('VERIFICATION_POPUP')
    }

    useEffect(() => {
        localStorage.setItem('verificationPopupShown', 'true')
    }, [])

    return (
        <Wrapper>
            <ClosePopup onClick={closePopup}>
                <img src={closeButton} alt="verificationClose" />
            </ClosePopup>
            <Title>{t.title}</Title>
            <Description>{t.description}</Description>
            <VerificationImage src={cprImage} alt="cpr-verification" />
            <BottomText>{t.bottom}</BottomText>
            <StyledButton
                onClick={() => (window.location = 'mailto:dokument@25syv.dk')}
            >
                {t.button}
            </StyledButton>
        </Wrapper>
    )
}

export default VerificationPopup
