import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import Icon from 'ui/Icon'
import deleteIcon from '@/images/icons/edit.svg'
import { BACKGROUNDS, COLORS } from 'themes'

const InputPlaceholder = styled.span`
    pointer-events: none;
    position: absolute;
    color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : COLORS.white};
    opacity: 1;
    font-size: 18px;
    text-align: ${(props) => (props.align ? props.align : 'left')};
    width: 85%;
    left: ${(props) => (props.leftIcon ? '40px' : 'initial')};
`

const textPosition = 4
const textBottomPosition = 15

const InputControl = styled.input.attrs((props) => ({
    autoComplete: props.autocomplete || 'off',
}))`
    border: none;
    background: none;
    min-width: 100px;
    max-width: 100%;
    width: ${({ suffix, width }) =>
        suffix ? (width ? `${width}` : '100px') : '100%'};
    padding: 0 !important;
    font-size: 1rem;
    color: ${(props) => (props.color ? props.color : COLORS.white)};
    font-weight: normal;
    transition: all 0.5s ease-in-out;
    margin-left: ${(props) => (props.leftIcon ? '27px' : 0)};
    text-align: ${({ align }) => align || 'left'};

    &::placeholder {
        color: transparent;
    }

    &:focus + span {
        line-height: 1;
        top: ${(props) => props.textPosition || textPosition}px;
        font-size: 0.67rem;
        color: ${COLORS.lightGrey};
    }

    &:not([value='']) + span {
        line-height: 1;
        top: ${(props) => props.textPosition || textPosition}px;
        font-size: 0.67rem;
    }

    &:focus ~ input,
    &:not([value='']) ~ input {
        visibility: visible;
    }

    /* Chrome, Safari, Edge, Opera */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */

    &[type='number'] {
        -moz-appearance: textfield;
    }
`
const InputSuffix = styled.input`
    -webkit-appearance: none !important;
    width: 70px;
    font-size: 54px;
    background: transparent;
    border: transparent;
    color: white;
    visibility: hidden;
`

const InputWrapper = styled.div`
    border: 1px solid ${(props) => (props.invalid ? COLORS.red : 'transparent')};
    position: relative;
    width: 100%;
    height: ${(props) => (props.height ? props.height : '60px')};
    background: ${(props) =>
        props.background ? props.background : BACKGROUNDS.grey200};
    border-radius: ${(props) => (!props.radius ? '4px' : props.radius)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    box-sizing: border-box;
    padding: ${({ wrapperPadding }) =>
        wrapperPadding ? wrapperPadding : '28px 15px'};
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
    margin-bottom: ${textBottomPosition + 10}px;
    ${({ wrapperMargin }) => (wrapperMargin ? 'margin: ' + wrapperMargin : '')};
`

const RightText = styled.div`
    position: absolute;
    right: 15px;
`

const LeftIcon = styled.div`
    position: absolute;
`

const Input = ({ align = 'left', innerRef, autoFocus = false, ...props }) => {
    const [subLabel, setSubLabel] = useState(true)
    const inputRef = innerRef || useRef(null)

    const onInputFocused = () => {
        if (props.value === '' && !props.showSublabel) {
            setSubLabel(false)
        }

        if (props.onInputFocusedHandler) {
            props.onInputFocusedHandler()
        }
    }

    const onInputBluered = () => {
        if (props.value === '') {
            setSubLabel(true)
        } else {
            setSubLabel(false)
        }

        if (props.onInputBlueredHandler) {
            props.onInputBlueredHandler()
        }
    }

    const onInputValueChanged = (event) => {
        event.stopPropagation()
        const { value } = event.target

        if (props.onChange) {
            props.onChange(value)
        }
    }

    const inputProps = {
        ...props,
        //value: this.props.value,
        onChange: onInputValueChanged,
        onFocus: onInputFocused,
        onBlur: onInputBluered,
        placeholder: props.placeholder,
        align,
        maxlength: props.maxLength,
    }

    useEffect(() => {
        setTimeout(() => {
            if (autoFocus) {
                if (inputRef.current) {
                    inputRef.current.focus()
                }
            }
        }, 500) // makes some delay for animation modal (otherwise autofocus brakes the animation)
    }, [])

    return (
        <InputWrapper
            {...props}
            onClick={() => {
                if (inputRef.current) {
                    return inputRef.current.focus()
                } else {
                    return inputRef.focus
                }
            }}
            invalid={props.validationError}
        >
            <LeftIcon>{props.leftIcon}</LeftIcon>
            <InputControl
                {...inputProps}
                ref={inputRef}
                align={align}
                textPosition={props.textPosition}
                suffix={props.suffix}
            />
            <InputPlaceholder
                placeholderColor={props.placeholderColor}
                leftIcon={props.leftIcon}
                align={align}
            >
                {props.placeholder}
            </InputPlaceholder>
            {props.suffix && <InputSuffix disabled value={props.suffix} />}
            <RightText>{props.rightText}</RightText>
            {!props.hideCancel && (
                <Icon
                    onClick={() => props.onChange('')}
                    size={'md'}
                    src={deleteIcon}
                    color={
                        props.cancelColor ? props.cancelColor : COLORS.lightGrey
                    }
                    style={{
                        width: '16px',
                        height: '16px',
                        position: 'absolute',
                        bottom: '50%',
                        zIndex: '1',
                        right: '20px',
                        transform: 'translateY(50%)',
                        cursor: 'pointer',
                        visibility: props.value ? 'inherit' : 'hidden',
                    }}
                />
            )}
            {props.validationError ? (
                <span
                    style={{
                        color: props.errorColor ? props.errorColor : COLORS.red,
                        fontSize: '0.67rem',
                        position: 'absolute',
                        bottom: '-' + textBottomPosition + 'px',
                        lineHeight: '1',
                    }}
                >
                    {props.validationError}
                </span>
            ) : subLabel ? (
                <span
                    style={{
                        color: COLORS.grey,
                        fontSize: '0.67rem',
                        position: 'absolute',
                        bottom: '-' + textBottomPosition + 'px',
                        lineHeight: '1',
                    }}
                >
                    {props.sublabel}
                </span>
            ) : null}
        </InputWrapper>
    )
}

Input.propTypes = {
    type: propTypes.string,
    align: propTypes.string,
    margin: propTypes.string,
    placeholder: propTypes.string,
    innerRef: propTypes.oneOfType([propTypes.object, propTypes.func]),
    value: propTypes.string,
    onChange: propTypes.func,
    textPosition: propTypes.string,
    maxLength: propTypes.number,
    suffix: propTypes.string,
    hideCancel: propTypes.bool,
}

export default Input
export { Input, InputWrapper, InputControl }
