import { BehaviorSubject, combineLatest } from 'rxjs'
import UserAdapter from 'common/adapters/UserAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import { ModalService } from 'services/ModalService'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import GamesService from 'services/GamesService'

const user = new BehaviorSubject({})
const profile = new BehaviorSubject({})
const isUserLoggedIn = new BehaviorSubject(false)
const clientId = new BehaviorSubject(null)
const avatar = new BehaviorSubject(1)

const clearUserState = () => {
    user.next({ loaded: true })
    profile.next({})
    isUserLoggedIn.next(false)
    clientId.next(0)
}

const attachUser = () => {
    UserAdapter.getProfile()
        .then((res) => {
            profile.next({ ...res })
            UserAdapter.attachUserToDevice(res).then((resAttachUser) => {
                if (resAttachUser.client_id && user.value.id) {
                    clientId.next(resAttachUser.client_id)
                }
            })
            UserAdapter.setLastLoginDate(res)

            IntercomAdapter.exec('update', {
                name: res.firstName + ' ' + res.lastName,
                user_id: res.userId,
                phone: res.phoneNr,
                postal_code: res.zip,
                email: res.email,
                username: res.userName,
            })
        })
        .catch((error) => {
            profile.next({})
            NotificationConductor.error(error.toString())
        })
}

const updateUserData = () => {
    return UserAdapter.isUserLoggedIn()
        .then((userData) => {
            if (userData) {
                const data = { ...userData, loaded: true }
                user.next(data)

                return data
            }
        })
        .catch(() => {
            clearUserState()
            NotificationConductor.error(
                'Something went wrong, please, try again later'
            )

            return { login: false }
        })
}

const checkUserLogin = () => {
    return UserAdapter.isUserLoggedIn()
        .then((userData) => {
            isUserLoggedIn.next(!!userData)
            if (userData) {
                user.next({ ...userData, loaded: true })
                GamesService.fetchOffers()
                return { login: true }
            } else {
                user.next({ loaded: true })
                clearUserState()
                localStorage.setItem('userId', 0)
                return { login: false }
            }
        })
        .catch(() => {
            clearUserState()
            NotificationConductor.error(
                'Something went wrong, please, try again later'
            )

            return { login: false }
        })
}

const getUserProfile = () =>
    combineLatest(user, profile, (v1, v2) => ({
        ...v1,
        ...v2,
    }))

const getPinLogin = () =>
    !!localStorage.getItem('pinHash') &&
    localStorage.getItem('pinUserName') === localStorage.getItem('userName')

const showPinLoginPopup = () => {
    if (detectMobile()) {
        const pinLoginStatus = localStorage.getItem('pinPopup')

        if ((!pinLoginStatus || pinLoginStatus === 'false') && !getPinLogin()) {
            ModalService.showModal('PIN_LOGIN_POPUP', {
                initialProps: {
                    animation: true,
                    bg: 'none',
                },
            })
        } else {
            ModalService.closeModal('LOGIN')
        }
    }
}

export default {
    attachUser,
    checkUserLogin,
    updateUserData,
    openLoginModal: () => {
        const pinHash = localStorage.getItem('pinHash')
        const userName = localStorage.getItem('userName')
        if (pinHash && userName) {
            ModalService.showModal('PIN_LOGIN', { action: 'check' })
        } else {
            ModalService.showModal('LOGIN', {
                initialProps: { bg: 'transparent' },
            })
        }
    },
    showPinLoginPopup,
    getProfile: () => {
        UserAdapter.getProfile()
            .then((res) => {
                profile.next({ ...res })
                IntercomAdapter.exec('update', {
                    name: res.firstName + ' ' + res.lastName,
                    user_id: res.userId,
                    phone: res.phoneNr,
                    postal_code: res.zip,
                    email: res.email,
                    username: res.userName,
                })
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    },
    getUserProfile,

    setIsUserLoggedIn: (cb = () => {}) => {
        return UserAdapter.isUserLoggedIn()
            .then((res) => {
                if (res) {
                    cb(res)

                    isUserLoggedIn.next(!!res)
                    localStorage.setItem('userName', res.userName)
                    localStorage.setItem('userId', res.id)
                    user.next({ ...res, loaded: true })
                } else {
                    clientId.next(0)
                    user.next({ loaded: true })
                }
                return new Promise((resolve, reject) => {
                    resolve('success')
                    reject('error')
                })
            })
            .catch((error) => {
                console.error(error)
            })
    },
    // nemIdLogin: (result, content) => {
    //     UserAdapter.nemIdLogin(result, content)
    //         .then((res) => {
    //             user.next(res)
    //             UserAdapter.getProfile()
    //                 .then((res) => {
    //                     profile.next({ ...res })
    //                 })
    //                 .catch((error) => {
    //                     NotificationConductor.error(error.toString())
    //                 })
    //         })
    //         .catch((error) => {
    //             NotificationConductor.error(error.toString())
    //         })
    // },
    getUserLoggedStatus: () => isUserLoggedIn.asObservable(),
    getUserInfo: () => user.asObservable(),
    //setUserInfo: (res) => user.next(res),
    getProfileInfo: () => profile.asObservable(),
    getUser: user,
    logOut: () => {
        UserAdapter.logout()
            .then(() => {
                clearUserState()
                localStorage.setItem('userId', 0)
                isUserLoggedIn.next(false)
                IntercomAdapter.exec('boot', { hide_default_launcher: true })
                IntercomAdapter.shutdown()
                GamesService.resetFavoriteGames()
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    },
    setClientId: (id) => clientId.next(id),
    getClientIdValue: () => new Promise((res) => res(clientId.value)),
    getClientId: () => clientId.asObservable(),
    getUserAvatar: () => avatar.asObservable(),
    setUserAvatar: (number = 1) => {
        localStorage.setItem('avatar', number)
        avatar.next(number)
    },
}
