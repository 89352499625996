export const BASIC_PROVIDERS = [
    {
        name: 'relax',
        value: 'relax',
        key: 'subprovider',
        label: 'Relax',
        category: 'provider',
        text: '',
    },
    {
        name: 'pragmatic',
        value: 'pragmatic',
        key: 'subprovider',
        label: 'Pragmatic play',
        category: 'provider',
        text: '',
    },
    {
        name: 'blueprint',
        value: 'blueprint',
        key: 'subprovider',
        label: 'Blueprint',
        category: 'provider',
        text: '',
    },
    {
        name: '1x2',
        value: '1x2',
        key: 'subprovider',
        label: '1x2 gaming',
        category: 'provider',
        text: '',
    },
    {
        name: 'redtiger',
        value: 'redtiger',
        key: 'subprovider',
        label: 'Red Tiger',
        category: 'provider',
        text:
            'Red Tiger lever og ånder, ifølge dem selv, for spil, og firmaet har da også siden 2014 skabt spil af høj kvalitet og rige på features. Firmaet er specielt kendt for deres daglige jackpots, som tilføjer spiloplevelsen masser af spænding og højt vinderpotentiale.',
    },
    {
        name: 'scientific',
        value: 'scientific',
        key: 'subprovider',
        label: 'Scientific Gaming',
        category: 'provider',
        text:
            'Scientific Games har været på markedet i mere end 85 år og er altså en af de ældste spillere på markedet. Det startede med fysiske automater, men gennem opkøb af andre virksomheder er de nu også begyndt at tilbyde online spilleautomater. Blandt nogle af deres ældste spil findes Zeus-automaterne, mens de i nyere tid blandt andet er kendt for Raging Rhino Megaways.',
    },
    {
        name: 'btg',
        value: 'btg',
        key: 'subprovider',
        label: 'Big Time Gaming',
        category: 'provider',
        text:
            'Big Time Gaming kom på markedet i 2011, og de har siden da revolutioneret industrien for online automater. Det er nemlig Big Time Gaming der introducerede Megaways-mekanismen, som siden er blevet udliciteret og kopieret af et hav af andre udbydere. Du har måske hørt om Bonanza, White Rabbit eller Extra Chilli, som alle er bygget på Megaways-mekanismen.',
    },
    {
        name: 'gamevy',
        value: 'gamevy',
        key: 'subprovider',
        label: 'Gamevy',
        category: 'provider',
        text:
            'Big Time Gaming kom på markedet i 2011, og de har siden da revolutioneret industrien for online automater. Det er nemlig Big Time Gaming der introducerede Megaways-mekanismen, som siden er blevet udliciteret og kopieret af et hav af andre udbydere. Du har måske hørt om Bonanza, White Rabbit eller Extra Chilli, som alle er bygget på Megaways-mekanismen.',
    },
    {
        name: 'lightbox',
        value: 'lightbox',
        key: 'subprovider',
        label: 'Lightning Box',
        category: 'provider',
        text:
            'Big Time Gaming kom på markedet i 2011, og de har siden da revolutioneret industrien for online automater. Det er nemlig Big Time Gaming der introducerede Megaways-mekanismen, som siden er blevet udliciteret og kopieret af et hav af andre udbydere. Du har måske hørt om Bonanza, White Rabbit eller Extra Chilli, som alle er bygget på Megaways-mekanismen.',
    },
    {
        name: 'netent',
        value: 'netent',
        key: 'subprovider',
        label: 'NetEnt',
        category: 'provider',
        text:
            'NetEnt - tidligere kendt som Net Entertainment - blev grundlagt i 1996 og var en af de allerførste online slot udbydere i verden. Deres største hits inkluderer Starburst, Aloha og Twin Spin, samt mere moderne favoritter som Lost Relics, Narcos, Hotline og Koi Princess.',
    },
    {
        name: 'playngo',
        value: 'playngo',
        key: 'subprovider',
        label: 'Play’n Go',
        category: 'provider',
        text:
            "Play'n GO er en af de største spillere i online casino industrien. Dette firma blev grundlagt i Sverige allerede i 1997 og har skabt en stor andel af de største hits inden for online spilleautomater. Vi kunne liste alle de utallige spil, men lad os starte med Reactoonz, Contact, Fire Joker, Book of Dead, Hugo 2, Cash Vandal og Sabaton. Tjek dem ud, hvis du ikke allerede har gjort det.",
    },
    {
        name: 'nolimit',
        value: 'nolimit',
        key: 'subprovider',
        label: 'Nolimit city',
        category: 'provider',
        text:
            'Nolimit City har siden 2016 været med til at tage spilindustrien til nye højder med spil af høj kvalitet bygget efter de nyeste standarder. Firmaets spil kombinerer spændende gameplay og spilmekanikker med potentiale for store gevinster. Et eksempel på dette er spillet Hot Nudge, som er et af Nolimit City’s mest populære.',
    },
    {
        name: 'wazdan',
        value: 'wazdan',
        key: 'subprovider',
        label: 'Wazdan',
        category: 'provider',
        text: '...',
    },
    {
        name: 'elk',
        value: 'elk',
        key: 'subprovider',
        label: 'ELK',
        category: 'provider',
        text:
            'Elk Studios blev grundlagt i 2013, siden da er det gået stærkt for det svenske firma. Deres spil er kendt for at være flotte og sjove, ligesom de har deres helt egne features i spillene',
    },
    {
        name: 'Hacksaw',
        value: 'hacksaw',
        key: 'subprovider',
        label: 'Hacksaw',
        category: 'provider',
        text:
            'Hacksaw så dagens lys i 2018, hvor de begyndte at designe skrabelodder. Senere bevægede de sig ind på markedet for online automater, hvor de som et ungt og ambitiøst firma var et friskt pust i en veletablerede branche. Deres spil er kendetegnende ved spændende og anderledes gameplay og deres høje volatilitet.',
    },
    {
        name: 'isoftbet',
        value: 'isoftbet',
        key: 'subprovider',
        label: 'iSoftBet',
        category: 'provider',
        text:
            'Spiludbyderen der er kendt for at have mange unikke spil og flere spil, som du kan genkende fra film og serier. Her kan Rango, Basic Instinct og Jack Bauer’s 24 timer nævnes. Også den populære Megaways feature kan findes blandt deres kæmpe spilsamling.',
    },
    {
        name: 'thunderkick',
        value: 'thunderkick',
        key: 'subprovider',
        label: 'Thunderkick',
        category: 'provider',
        text:
            'Spiludbyderen der er kendt for at have mange unikke spil og flere spil, som du kan genkende fra film og serier. Her kan Rango, Basic Instinct og Jack Bauer’s 24 timer nævnes. Også den populære Megaways feature kan findes blandt deres kæmpe spilsamling.',
    },
    {
        name: 'yggdrasil',
        value: 'yggdrasil',
        key: 'subprovider',
        label: 'Yggdrasil',
        category: 'provider',
        text:
            'Dette maltesiske 2013-firma er en af de bedste spiludbydere på markedet i dag og deres spil er visuelt imponerende. Her er et par af de mere populære: Vikings Go Berzerk, Golden Fish Tank, Gem Rocks og Seasons.',
    },
    {
        name: 'playson',
        value: 'playson',
        key: 'subprovider',
        label: 'Playson',
        category: 'provider',
        text:
            'Spiludbyderen der er kendt for at have mange unikke spil og flere spil, som du kan genkende fra film og serier. Her kan Rango, Basic Instinct og Jack Bauer’s 24 timer nævnes. Også den populære Megaways feature kan findes blandt deres kæmpe spilsamling.',
    },
    {
        name: 'quickspin',
        value: 'quickspin',
        key: 'subprovider',
        label: 'Quickspin',
        category: 'provider',
        text:
            'Quickspin oprinder fra Sverige og kombinerer stil og flair når det kommer til at skabe deres selvudråbte "Verdens bedste casinospil”. Med et team af industriveteraner har Quickspin rykket markedet op på nye niveauer, både med hensyn til innovation og kvalitet. Faktisk har de til dato skabt nogle af de mest elskede og efterspurgte casinospil.',
    },
]

export const PROVIDERS = [
    ...BASIC_PROVIDERS,
    {
        name: 'evolution',
        value: 'evolution',
        key: 'subprovider',
        label: 'Evolution gaming',
        category: 'provider',
        text:
            'Evolution Gaming har valgt at satse anderledes end de typiske online casino virksomheder. I stedet for at skabe spilleautomater fokuserer dette svenske selskab fra 2006 kun på live casino spil. Evolution Gaming er kendt for at være den førende inden for live casino - og du kan endda finde danske dealere på nogle af bordene, så du rigtigt kan føle dig hjemme.',
    },
]
